<template>
  <div>
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'DevicesAssignedToYou'">
      <template v-slot:right-end>
        <div class="vx-col">
          <dynamic-customers-drop-down
            :placeholder="$t('SearchCustomer')"
            @on-item-selected="onCustomerChanged($event)">
          </dynamic-customers-drop-down>
        </div>
        <div class="vx-col mt-5">
          <vs-button to="/assign/devices" type="filled" icon-pack="feather">
            {{ $t('AssignDevices') }}
          </vs-button>
        </div>
      </template>
    </breadcrumb-base>

    <!-- Error List State-->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
               class="mx-auto mb-4 max-w-full">
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div class="vx-row">
        <div class="w-full">
          <!-- Empty List State -->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="devices.length === 0 && !errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                     class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
              </div>
            </div>
          </transition>
          <vs-table-modified v-show="devices.length > 0 && !errorFetching" ref="table"
                             :max-items="itemsPerPage"
                             :data="devices">

            <template slot="header">
              <div class="flex flex-row items-center justify-center space-x-2 w-full">

                <div
                  class="con-input-search vs-table--search">
                  <!-- @input="searchDebounce"-->
                  <vs-input
                    class="input-search"
                    :disabled="isSearching"
                    v-model="searchText"
                    type="text"
                    icon-no-border icon="search"/>
                </div>

                <vs-button cl @click="searchDevices()">{{ $t('Search') }}</vs-button>

              </div>
            </template>


            <template slot="thead">
              <vs-th>{{ $t('SNO') }}</vs-th>
              <vs-th >{{ $t('CustomerID') }}</vs-th>
              <vs-th >{{ $t('Organisation') }}</vs-th>
              <vs-th >{{ $t('DeviceSerialNo') }}</vs-th>
              <vs-th >{{ $t('DeviceStatus') }}</vs-th>
              <vs-th >{{ $t('DeviceLastUpdated') }}</vs-th>
            </template>
            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <p class="user-name font-medium truncate">{{ indextr + 1 }}</p>
                </vs-td>

                <vs-td>
                  <div class="vs-col">
                    <p class="user-name font-medium truncate">{{ tr.child_customer_id }}</p>
                    <p class="product-name font-medium truncate">{{ tr.child_organisation | capitalize }}</p>
                  </div>
                </vs-td>

                <vs-td>
                  <div class="vs-col">
                    <p class="truncate">{{ tr.child_first_name }} {{ tr.child_last_name }}</p>
                    <p style="font-size: 11px; color: black">
                      {{ tr.child_address }}<span v-if="tr.child_house_no"> {{ tr.child_house_no }}</span>
                    </p>
                    <p style="font-size: 11px; color: black">
                      {{ tr.child_postal }} {{ tr.child_city }}
                    </p>
                  </div>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.device_serial_no }}</p>
                </vs-td>

                <vs-td>
                  <div class="text-center flex justify-center">
                    <vs-chip :color="getStatusColor(tr.status)" class="user-status">{{
                        getStatusText(tr.status) | title
                      }}
                    </vs-chip>
                  </div>
                </vs-td>

                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.updated | dateFormat }}</p>
                </vs-td>

              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>
            <template slot="footer-left">
              <div class="flex">
                <vs-new-pagination :total="vTotalPages" v-model="cPage" v-on:change="listPageChanged">
                </vs-new-pagination>
              </div>
            </template>
          </vs-table-modified>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import DynamicCustomersDropDown from '@/views/pages/superadmin/customer/DynamicCustomersDropDown'

export default {
  name: 'ViewDevices',
  components: {
    DynamicCustomersDropDown,
  },
  data() {
    return {
      customer: null,
      cPage: 1,
      itemsPerPage: 20,
      isMounted: false,
      errorFetching: false,
      isSearching: false,
      searchText: "",
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Devices', i18n: 'Devices', active: true },
      ],
    }
  },
  computed: {
    devices() {
      return this.$store.state.devices.devices
    },
    vPage() {
      return this.$store.state.devices.vPage
    },
    vTotalPages() {
      return this.$store.state.devices.vTotalPages
    },
  },
  methods: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    onCustomerChanged(c) {
      this.customer = c
      this.fetchDevices()
    },
    openEditDevice(data) {
      this.$router.push({ name: 'device-edit', params: { data: btoa(JSON.stringify(data)) } })
    },
    openAddDevice() {
      this.$router.push({ name: 'device-add' })
    },
    getStatusText(status) {
      if (status === 1) return 'Active'
      return 'InActive'
    },
    getStatusColor(status) {
      if (status === 1) return 'success'
      return 'warning'
    },
    getInvertStatusColor(status) {
      if (status === 1) return 'warning'
      return 'success'
    },
    updateDeviceStatus(data) {
      const payload = {
        serial_no: data.serial_no,
        device_status: data.status === 1 ? 0 : 1,
        tag: data.tag,
        update: 1,
      }
      this.$vs.loading()
      this.$store.dispatch('devices/addUpdateDevices', { payload })
        .then((data) => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          this.$vs.loading.close()
          let msg = ''
          try {
            try {
              msg = error.data.message
            } catch (e) {
              msg = error.response.data.message
            }
          } catch (e) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    searchDevices() {
      this.isSearching = true
      this.fetchDevices()
    },
    listPageChanged(item) {
      this.fetchDevices(this.cPage - 1)
    },
    fetchDevices(page = '0') {
      const payload = {}
      if (this.customer != null) {
        payload.customer_id = this.customer.customer_id
      }
      if(this.searchText) {
        payload.search_text = this.searchText
      }
      this.isSearching = true
      payload.page = page;
      this.$vs.loading()
      return this.$store.dispatch('devices/fetchResellerDevices', { payload })
        .then((data) => {
          this.isSearching = false
          this.$vs.loading.close()
        })
        .catch((error) => {
          this.isSearching = false
          this.$vs.loading.close()
          let msg = ''
          try {
            try {
              msg = error.data.message
            } catch (e) {
              msg = error.response.data.message
            }
          } catch (e) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    // this.fetchDevices()
  },
}
</script>

<style scoped>

</style>
